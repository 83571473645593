import React from 'react'
import BaseComponent, { errorMap, parseDate } from './BaseComponent'
import Modal from './Modal'
import Files from './Files'
import _ from 'lodash'

export default class ImportPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { confirm: false, preloader: false, geocodeLoader: false, ident: "", file: null, showStatus: false, maps: [], mapUUID: "", geocodeLoaderIndex: -1 }
    this._bind('getFile', 'onFileChange', 'showConfirm', 'closeConfirm', 'sendFile')
  }

  getFile(e) {
    e.preventDefault()
    this.setState({ preloader: true })
    let url = this.props.API+"/map/template"
    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: null
      })
    .then((response) => {
        return response.text()
    })
    .then((result) => {
      console.log(result)
      this.setState({ preloader: false })
    }, (error) => {
      console.log(error)
    })
  }

  onFileChange(file) {
    this.setState({
      file: file
    })
  }

  getLatestMap() {
    let url = this.props.API+"/map/list"
    let params = {
      offset: 0,
      limit: 20
    }

    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
      } else {
        const geocoded = _.filter(result.data.maps, {geocoded: true})
        const lastMap = geocoded[0] || {}
        this.setState({ mapUUID: lastMap.uuid, maps: result.data.maps })
        for (let i = 0; i < result.data.length; i++) {
          const element = result.data[i];
          if(element.imported && !element.geocoded) {
            setTimeout(() => { this.getStatus(element.uuid, i)}, 500)
          }
        }
      }
    }, (error) => {
      console.log(error)
    })
  }

  getStatus(mapUUID, mapIndex) {
    let url = this.props.API+"/map/describe"
    let params = {
      map: {
        uuid: mapUUID
      }
    }

    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
      } else {
        console.log(result)
        let tempMaps = this.state.maps
        tempMaps[mapIndex] = result.data
        this.setState({ maps: tempMaps })
      }
    }, (error) => {
      console.log(error)
    })
  }

  geocode(mapIndex) {
    let url = this.props.API+"/map/geocode"
    let params = {}

    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
      if(response.status === 504) {
        setTimeout(() => { this.geocode(mapIndex) }, 30000)
      } else {
        return response.json()
      }
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
      } else {
        if(result.data?.addressess?.found?.hash === 0 && result.data?.addressess?.found?.geocode === 0) {
          this.setState({ geocodeLoader: -1 })
          this.getStatus(result?.data?.map?.uuid, mapIndex)
        } else {
          this.geocode(mapIndex)
          this.getStatus(result?.data?.map?.uuid, mapIndex)
        }
      }
    }, (error) => {
      console.log(error)
    })
  }

  sendFile(e) {
    e.preventDefault()
    this.setState({ preloader: true })
    let formData = new FormData()
    formData.append('files[0]', this.state.file, this.state.file.name);

    let data = formData;

    let url = this.props.API+"/map/import"
    fetch(url, {
      method: "POST",
      headers: {
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: data
    })
    .then((response) => {
      if(response.status === 504) {
        this.setState({ preloader: false })
        this.getLatestMap()
      } else {
        return response.json()
      }
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
        this.setState({ preloader: false })
      } else {
        this.setState({ preloader: false })
        if(result.data[0]) result.data[0].name = this.state.files[0]?.name
        this.props.onChange(result.data[0])
        this.getLatestMap()
      }
    }, (error) => {
      //console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
      this.setState({ preloader: false })
    })
  }

  showConfirm() {
    this.setState({ confirm: true })
  }

  closeConfirm(e) {
    e.preventDefault()
    this.setState({ confirm: false, file: null, ident: "" })
  }

  componentDidMount() {
    this.getLatestMap()
  }

  render () {
    console.log(this.state.maps)

    const Confirm = (
      <div className="confirm">
        <h4>Are you sure to import this file?</h4>
        <div className="btn-holder">
          <button className="btn" onClick={ this.closeConfirm }><span>No</span></button>
          <button className="btn" onClick={ this.sendFile }><span>Yes</span></button>
        </div>
      </div>
    )

    let fields = this.props.data.fields.map((item, i) => {
      if (item.type === "getFile") {
        return (
          <div key={ i } className="title">
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <button className="btn" onClick={ this.getFile }><span>Download</span></button>
          </div>
        )
      } else if (item.type === "sendFile") {
        return (
          <div key={ i }>
            <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
            { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
            <Files API={this.props.API} file={ this.state.file } name={ "file" } onFileChange={ this.onFileChange }/>
            <button className="btn" onClick={ this.sendFile }><span>Import</span></button>
          </div>
        )
      } else {
        return null
      }
    })

    return (
      <section className="new">
        <div className="container">
          <h1>Import</h1>
          <form className="fields" data-parsley-validate>
            { fields }
            { this.state.preloader ? <div className="preloader"><span></span></div> : null }
            <div className='title'>
              <h2>Map places import status</h2>
              <ul className='map-list'>
                { this.state.maps.map((item, i) => {
                  return (
                    <li key={i}>
                      { this.state.geocodeLoaderIndex === i ? <div className="preloader"><span></span></div> : null }
                      <div>
                        <p><strong>Map ID: </strong> {i+1}</p>
                        <p><strong>Created date: </strong> {parseDate(item?.created?.date)}</p>
                        <p><strong>Imported: </strong> {item?.imported ? "true" : "false"}</p>
                        <p><strong>Geocoded: </strong> {item?.geocoded ? "true" : "false"}</p>
                        { this.state.geocodeLoaderIndex === i && <p><strong>Imported addresses: </strong> {item?.addresses?.imported}</p>}
                        { this.state.geocodeLoaderIndex === i && <p><strong>Geocoded addresses: </strong> {item?.addresses?.geocoded}</p>}
                        { this.state.geocodeLoaderIndex === i && <p><strong>Progress: </strong> {((item?.addresses?.geocoded / item?.addresses?.imported) * 100).toFixed(2)}%</p> }
                      </div>
                      <div>
                        { !item?.geocoded && item?.imported ? <button className="btn" onClick={(e) => { e.preventDefault(); this.setState({ geocodeLoaderIndex: i }); this.geocode(i) }}><span>Geocoding</span></button> : !item?.geocoded && !item?.geocoded ? <button className="btn" onClick={(e) => { e.preventDefault(); this.getLatestMap() }}><span>Refresh</span></button> : null }
                      </div>
                    </li>
                  )
                }) }
              </ul>
            </div>
          </form>
        </div>
        <Modal open={ this.state.confirm } hidePopup={ this.closeConfirm }>{ Confirm }</Modal>
      </section>
    )
  }
}
