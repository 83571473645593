import React from 'react'
import BaseComponent, { errorMap } from './BaseComponent'
import Modal from './Modal'
import _ from 'lodash'
import ReactPaginate from 'react-paginate';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker";
import pl from 'date-fns/locale/pl';

registerLocale('pl', pl)

export default class ListPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { list: [], itemId: null, preloader: true, searchTerm: "", page: 0, pages: 0, category: "test", sortBy: "publicationDate", filters: {}, mapUUID: "", dateBefore: new Date(), dateAfter: new Date(), email: "", phone: "", type: "sfId", limit: 20, offset: 0, balance: 0, code: "" }
    this._bind('delete', 'getData', 'searchUpdated', 'closeConfirm', 'handlePageClick')

    this.handleSearch = _.debounce( () => {
      this.setState({ list: [], preloader: true })
      this.getData(this.state.mapUUID)
    }, 600);
  }

  getData(mapUUID) {
    let url = this.props.API+this.props.data.endpointList
    let params = {
      consumer: {
        sfId: this.state.searchTerm
      },
      coupon: {
        code: this.state.code
      }
    }

    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
      } else {
        //console.log(result)
        let res = result.data || []
        if (res === null) {
          res = []
        }
        const ordered = res
        this.setState({ pages: Math.ceil(result.data?.total?.length/20) })
        this.setState({ list: ordered, preloader: false, pages: result.data ? result.data.pages : [] })
      }
    }, (error) => {
      console.log(error)
    })
  }

  getLatestMap() {
    let url = this.props.API+"/map/list"
    let params = {
      offset: 0,
      limit: 20
    }

    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident)}}), true);
      } else {
        const geocoded = _.filter(result.data, {geocoded: true})
        const lastMap = geocoded[0]
        console.log(lastMap)
        this.setState({ mapUUID: lastMap?.uuid })
        this.getData(lastMap?.uuid)
      }
    }, (error) => {
      console.log(error)
    })
  }

  componentDidMount() {
    if(this.props.data.table === "address") {
      this.getLatestMap()
    } else {
      // this.getData()
    }
  }

  delete(id) {
    this.setState({ preloader: true })
    let url = this.props.API+this.props.data.endpointDelete
    const params = {
      [this.props.data.table]: {
        uuid: id
      }
    }
    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: JSON.stringify(params)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      //console.log(result)
      this.setState({ confirm: false, itemId: null })
      this.getData()
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie usunięto."}}), true);
      }
    }, (error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Something went wrong."}}), true);
      this.setState({ preloader: false })
    })
  }

  searchUpdated(term) {
    this.setState({searchTerm: term})
  }

  closeConfirm() {
    this.setState({ confirm: false, itemId: null })
  }

  handlePageClick(data) {
    this.setState({ page: data.selected, preloader: true }, () => {
      this.getData(this.state.mapUUID)
    })
  }

  render () {
    let list = null

    let filteredList = this.state.list

    const Confirm = (
      <div className="confirm">
        <h4>Are you sure?</h4>
        <div className="btn-holder">
          <button className="btn" onClick={ this.closeConfirm }><span>No</span></button>
          <button className="btn" onClick={ this.delete.bind(null, this.state.itemId) }><span>Yes</span></button>
        </div>
      </div>
    )

    if (filteredList?.length > 0) {
      const sortedList = this.props.data.table === "articles" || this.props.data.table === "offers" || this.props.data.table === "events" || this.props.data.table === "faq" ? _.orderBy(filteredList, [this.state.sortBy], ['desc']) : filteredList
      list = sortedList.map((item, i) => {
        return (
          <li key={ i }>
            <JSONPretty id="json-pretty" data={item}></JSONPretty>
          </li>
        )
      })
    }

    return (
      <section className="list">
        <div className="container">
    			<h1>{ this.props.data.name }</h1>
          <div className='s-box'>
            <label>sfId:</label>
            <div className="">
              <input type="text" placeholder="sfId" value={ this.state.searchTerm } onChange={ (e) => { this.setState({ searchTerm: e.target.value })} }/>
            </div>
            <label>Coupon code:</label>
            <div className="">
              <input type="text" placeholder="code" value={ this.state.code } onChange={ (e) => { this.setState({ code: e.target.value })} }/>
            </div>
            <button onClick={this.handleSearch}>Invalidate</button>
          </div>
          <JSONPretty id="json-pretty" data={filteredList}></JSONPretty>
	  	  </div>
        <Modal open={ this.state.confirm } hidePopup={ this.closeConfirm }>{ Confirm }</Modal>
      </section>
    )
  }
}
