import React from 'react'
import BaseComponent from './BaseComponent'

export default class HomePage extends BaseComponent {

  render() {
    return (
      <section className="home">
        <div className="container">
        	<h1>Hello, { this.props.user.firstName }</h1>
        </div>
      </section>
    )
  }
}
