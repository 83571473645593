import React from 'react'
import BaseComponent from './BaseComponent'
import Dropzone from 'react-dropzone'

export default class SingleImageUpload extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { files: [], imageError: false, preloader: false, preview: "" }
    this._bind('onDrop')
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if(acceptedFiles.length > 0) {
        this.setState({
            imageError: false,
            files: acceptedFiles
        })
        this.props.onFileChange(acceptedFiles[0])
    } else if(rejectedFiles.length > 0) {
        console.log("rejectedFiles")
        this.setState({
            imageError: true
        })
    }
  }

  render () {
    let fileName = this.state.files.length > 0 ? this.state.files[0].name : this.props.file || ""

    return (
      <div className="dropzone-holder">
        <Dropzone maxSize={20000000} accept={ ["text/csv"]} className={ this.state.imageError ? "dropzone dropzone-reject" : "dropzone" } activeClassName="dropzone-active" rejectClassName="dropzone-reject" multiple={ false } onDrop={ this.onDrop }>
        {({getRootProps, getInputProps}) => (
            <div className={ this.state.imageError ? "dropzone dropzone-reject" : "dropzone" }>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                { fileName ? <p>Nazwa pliku: { fileName }</p> : <p>Przeciągnij i upuść plik, lub kliknij aby wybrać.</p> }
              </div>
            </div>
          )}
        </Dropzone>
        { this.props.file && this.props.file.uuid ? <div className="remove-file" onClick={ () => { this.removeFiles() } }>Usuń</div> : null }
        { this.state.preloader ? <div className="preloader"><span></span></div> : null }
      </div>
    )
  }
}
