import React, { useState, useEffect } from 'react'
import { errorMap } from './BaseComponent'
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

export default function Categories({ API, checked = [], setChecked }) {
    const [categories, setCategories] = useState([{value: "", label: ""}])
    const [expanded, setExpanded] = useState([])
  
    const getCategories = () => {
      let url = API+"/admin/permissions"
      const params = {
        "limit": 100,
        "offset": 0,
        archived: false
      }
  
      fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
          },
          body: JSON.stringify(params)
        })
      .then((response) => {
          return response.json()
      })
      .then((result) => {
        if (result.status.success) {
          setCategories(result.data)
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error) }}), true);
        }
      }, (error) => {
        console.log(error)
      })
    }
  
    useEffect(() => {
      getCategories()
      // eslint-disable-next-line
    }, [])
    
    return (
      <>
        <CheckboxTree
            nodes={categories}
            checked={checked || []}
            expanded={expanded || []}
            onCheck={checked => { setChecked(checked) }}
            onExpand={expanded => { setExpanded(expanded) }}
            showExpandAll={true}
        />
      </>
    )
  }