import React from 'react'
import BaseComponent, { errorMap } from './BaseComponent'
import Modal from './Modal'
import _ from 'lodash'
import ReactPaginate from 'react-paginate';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker";
import pl from 'date-fns/locale/pl';
import clipboard from '../../img/clipboard.svg';

registerLocale('pl', pl)

export default class ListPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { list: [], listOfCoupons: [], showListOfCoupons: false, itemId: null, preloader: true, userName: "", page: 0, pages: 0, category: "test", sortBy: "publicationDate", filters: {}, mapUUID: "", dateBefore: new Date(), dateAfter: new Date(), email: "", phone: "", type: "sfId", ident:"", limit: 20, offset: 0 }
    this._bind('delete', 'getData', 'userNameUpdated', 'closeConfirm', 'handlePageClick')

    this.handleSearch = _.debounce( () => {
      this.setState({ list: [], preloader: true })
      this.getData(this.state.mapUUID)
    }, 600);
  }

  getData(mapUUID) {
    let url = this.props.API+this.props.data.endpointList
    let params = {
        catalogue: this.state.ident,
    }

    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
      } else {
        //console.log(result)
        let res = result.data || []
        if (res === null) {
          res = []
        }
        const ordered = res
        this.setState({ pages: Math.ceil(result.data?.total?.length/20) })
        this.setState({ list: ordered, preloader: false, pages: result.data ? result.data.pages : [] })
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie wyzerowano."}}), true);
      }
    }, (error) => {
      console.log(error)
    })
  }


  getLatestMap() {
    let url = this.props.API+"/coupon/catalogues"
    let params = {
      offset: 0,
      limit: 20
    }

    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident)}}), true);
      } else {
        result.data?.catalogues.forEach((element) => console.log(element.ident));
        this.setState({ listOfCoupons: result.data?.catalogues })
        // this.getData(lastMap?.uuid)
      }
    }, (error) => {
      console.log(error)
    })
  }

  componentDidMount() {
      this.getLatestMap()
    // if(this.props.data.table === "address") {
    // } else {
    //   // this.getData()
    // }
  }

  delete(id) {
    this.setState({ preloader: true })
    let url = this.props.API+this.props.data.endpointDelete
    const params = {
      [this.props.data.table]: {
        uuid: id
      }
    }
    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: JSON.stringify(params)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      //console.log(result)
      this.setState({ confirm: false, itemId: null })
      this.getData()
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie wyzerowano."}}), true);
      }
    }, (error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Something went wrong."}}), true);
      this.setState({ preloader: false })
    })
  }

  userNameUpdated(name) {
    this.setState({userName: name})
  }

  closeConfirm() {
    this.setState({ confirm: false, itemId: null })
  }

  handlePageClick(data) {
    this.setState({ page: data.selected, preloader: true }, () => {
      this.getData(this.state.mapUUID)
    })
  }

  render () {
    let list = null
    let filteredList = this.state.list

    let listOfCoupons = this.state.listOfCoupons
    let showListOfCoupons = this.state.showListOfCoupons

    const Confirm = (
      <div className="confirm">
        <h4>Are you sure?</h4>
        <div className="btn-holder">
          <button className="btn" onClick={ this.closeConfirm }><span>No</span></button>
          <button className="btn" onClick={ this.delete.bind(null, this.state.itemId) }><span>Yes</span></button>
        </div>
      </div>
    )

    const handleParseDate = date => {
        const dateToFormat = new Date(date);
        const formatter = new Intl.DateTimeFormat("pl-PL", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
  
        return formatter.format(dateToFormat);
    };

    const handleHoursDate = date => {
        const dateToFormat = new Date(date);
        const formatter = new Intl.DateTimeFormat("pl-PL", {
            hour: "numeric",
            minute: "numeric",
        });
  
        return formatter.format(dateToFormat);
    };

    if (filteredList?.length > 0) {
      const sortedList = this.props.data.table === "articles" || this.props.data.table === "offers" || this.props.data.table === "events" || this.props.data.table === "faq" ? _.orderBy(filteredList, [this.state.sortBy], ['desc']) : filteredList
      list = sortedList.map((item, i) => {
        return (
          <li key={ i }>
            <JSONPretty id="json-pretty" data={item}></JSONPretty>
          </li>
        )
      })
    }

    let today = new Date() 

    return (
      <section className="list">
        <div className="container">
    		<h1>{ this.props.data.name }</h1>
          <div className='s-box'>
            <label>Show all coupons </label>
            <input type="checkbox" placeholder="show" value={ this.state.showListOfCoupons } onChange={ (e) => { this.setState({ showListOfCoupons: !showListOfCoupons })} }/>
            {showListOfCoupons && <div className='coupon-holder'>
                {listOfCoupons.map((element, index) => {
                    return (
                        <div className='box' key={index}>
                            <div>
                            <span>ident: </span>
                                <strong >{element?.ident}</strong>
                                <button onClick={ (e) => {navigator.clipboard.writeText(element?.ident); window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Ident copied."}}), true);}}>
                                    <img src={clipboard} />
                                </button>
                            </div>
                            <div className='ident'>
                                <span>title: </span>
                                <strong > {element?.title}</strong>
                            </div>
                            {/* <div>
                                <span>ident: </span>
                                <strong key={index}>{element?.ident}</strong>
                            </div> */}
                            <div>
                                <span>Visibility: </span><br />
                                <strong>{handleParseDate(element?.visibleFrom)}&nbsp;</strong> 
                                <span>({handleHoursDate(element?.visibleFrom)})</span>
                                &nbsp;-&nbsp;  
                                <strong>{handleParseDate(element?.visibleTo)}&nbsp;</strong>
                                <span>({handleHoursDate(element?.visibleTo)})</span>
                            </div>
                            <div>
                                <span>coupon expiration: </span><br />
                                {typeof element?.expirationModifier === "string" && <>
                                    <strong>{element?.expirationModifier}</strong>
                                </> }
                                {typeof element?.expirationDate === "string" && <>
                                  <strong>{handleParseDate(element?.expirationDate)}&nbsp;</strong> 
                                  <span>({handleHoursDate(element?.expirationDate)})</span>
                                </> }
                                {typeof element?.expirationDate !== "string" && typeof element?.expirationModifier !== "string" && <>
                                  <span><i>n/d</i></span>
                                </> }              
                            </div>
                        </div>
                    )
                })}
            </div>}
            <br/><br/>
            <label>Ident :</label>
            <div className="">
              <input type="text" placeholder="type ident" value={ this.state.ident } onChange={ (e) => { this.setState({ ident: e.target.value })} }/>
            </div>
            <button onClick={this.handleSearch}>Generate coupon</button>
          </div>
          <JSONPretty id="json-pretty" data={filteredList}></JSONPretty>
	  	  </div>
        <Modal open={ this.state.confirm } hidePopup={ this.closeConfirm }>{ Confirm }</Modal>
      </section>
    )
  }
}
