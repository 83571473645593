import React from 'react'
import BaseComponent, { errorMap } from './BaseComponent'
import Modal from './Modal'
import _ from 'lodash'
import ReactPaginate from 'react-paginate';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker";
import pl from 'date-fns/locale/pl';

registerLocale('pl', pl)

export default class ListPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { list: [], itemId: null, preloader: true, searchTerm: "", page: 0, pages: 0, category: "test", sortBy: "publicationDate", filters: {}, mapUUID: "", dateBefore: new Date(), dateAfter: new Date(), email: "", phone: "", type: "sfId", limit: 20, offset: 0, anonToken: "" }
    this._bind('delete', 'getData', 'searchUpdated', 'closeConfirm', 'handlePageClick')

    this.handleSearch = _.debounce( () => {
      this.setState({ list: [], preloader: true })
      this.getData(this.state.mapUUID)
    }, 600);

    this.handleAnonimize = _.debounce( () => {
      this.delete(this.state.anonToken)
    }, 600);
  }

  getData(mapUUID) {
    let url = this.props.API+"/consumer/unverified/find"
    let params = {
      consumer: {
        mobilePhone: this.state.searchTerm
      }
    }

    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
      } else {
        let res = result.data || []
        if (res === null) {
          res = []
        }
        const ordered = res
        this.setState({ pages: Math.ceil(result.data?.total?.length/20) })
        this.setState({ list: ordered, preloader: false, pages: result.data ? result.data.pages : [] })
        this.setState({ anonToken: result.data.token.uuid })
      }
    }, (error) => {
      console.log(error)
    })
  }

  getLatestMap() {
    let url = this.props.API+"/consumer/unverified/find"
    let params = {
      offset: 0,
      limit: 20
    }

    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident)}}), true);
      } else {
        const geocoded = _.filter(result.data, {geocoded: true})
        const lastMap = geocoded[0]
        console.log(lastMap)
        this.setState({ mapUUID: lastMap?.uuid })
        this.getData(lastMap?.uuid)
      }
    }, (error) => {
      console.log(error)
    })
  }

  componentDidMount() {
    if(this.props.data.table === "address") {
      this.getLatestMap()
    } else {
      // this.getData()
    }
  }

  delete(token) {
    this.setState({ preloader: true })
    let url = this.props.API+"/consumer/unverified/anonymize"
    const params = {
      consumer: {
        mobilePhone: this.state.searchTerm
      }
    }
    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + token
      },
      body: JSON.stringify(params)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie zanonimizowano."}}), true);
      }
    }, (error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Something went wrong."}}), true);
      this.setState({ preloader: false })
    })
  }

  searchUpdated(term) {
    this.setState({searchTerm: term})
  }

  closeConfirm() {
    this.setState({ confirm: false, itemId: null })
  }

  handlePageClick(data) {
    this.setState({ page: data.selected, preloader: true }, () => {
      this.getData(this.state.mapUUID)
    })
  }

  render () {
    let list = null

    let filteredList = this.state.list

    const Confirm = (
      <div className="confirm">
        <h4>Are you sure?</h4>
        <div className="btn-holder">
          <button className="btn" onClick={ this.closeConfirm }><span>No</span></button>
          <button className="btn" onClick={ this.delete.bind(null, this.state.itemId) }><span>Yes</span></button>
        </div>
      </div>
    )

    if (filteredList?.length > 0) {
      const sortedList = this.props.data.table === "articles" || this.props.data.table === "offers" || this.props.data.table === "events" || this.props.data.table === "faq" ? _.orderBy(filteredList, [this.state.sortBy], ['desc']) : filteredList
      list = sortedList.map((item, i) => {
        return (
          <li key={ i }>
            <JSONPretty id="json-pretty" data={item}></JSONPretty>
          </li>
        )
      })
    }

    return (
      <section className="list">
        <div className="container">
    			<h1>{ this.props.data.name }</h1>
          <div className='s-box'>
            <label>Phone:</label>
            <div className="">
              <input type="text" placeholder="phone with +48" value={ this.state.searchTerm } onChange={ (e) => { this.setState({ searchTerm: e.target.value })} }/>
            </div>
            <button onClick={this.handleSearch}>Find</button>
          </div>
          <JSONPretty id="json-pretty" data={filteredList}></JSONPretty>
          <button disabled={ !this.state.anonToken } onClick={this.handleAnonimize}>Anonymize</button>
          <br />
          <br />
          <br />
          <h3>Opis narzędzia</h3>
          Narzędzie służące do anonimizacji numeru telefonu, zanim user potwierdzi swój email. <br />
          Przykładowe użycie: user zrobił literówkę w emailu i potrzebuje przejść proces rejestracji od nowa, ale zablokowany numer tel mu nie pozwala. 
          <br />
          <h3>Instrukcja</h3>
          <ol>
            <li>Wprowadź numer user (pamiętaj o kierunkiwym np. +48)</li>
            <li>Kliknij przycisk <strong>Find</strong></li>
            <li>Jeśl user zarejestorał się, ale nie potwierdził emaila, jego dane zostaną wyświetlone po klikniętym przyciskiem</li>
            <li>Żeby zanonimizować jego numer, kliknij przycisk <strong>Anonymize</strong>, który powinien być już odblokowany</li>
            <li>W prawym górnym roku, powinno wyświetlić sie powiadomienie o pomyślnej anonimizacji</li>
          </ol>
          <h3>legenda błędów</h3>
          <p><code style={{borderLeft: "3px solid #b53030", padding: "4px 4px 4px 6px", boxShadow: "rgba(0, 0, 0, 0.25) 4px 4px 30px"}}>error_consumer_not_found</code> - Nie ma usera z takim numerem telefonu. Czyli albo nigdy nie było, albo został już zanonimizowany. <strong>Można się rejestrować na ten numer.</strong></p>
          <p><code style={{borderLeft: "3px solid #b53030", padding: "4px 4px 4px 6px", boxShadow: "rgba(0, 0, 0, 0.25) 4px 4px 30px"}}>error_consumer_already_exists</code> - user potwierdził email, albo nawet się już logował.</p>
	  	  </div>
        <Modal open={ this.state.confirm } hidePopup={ this.closeConfirm }>{ Confirm }</Modal>
      </section>
    )
  }
}
