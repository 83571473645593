import React, { useState, useEffect } from 'react'
import { errorMap } from './BaseComponent'

export default function Addresses({ API, selectAddress, selectedAddr, required }) {
    const [addresses, setAddresses] = useState([])
  
    const getAddresses = () => {
      let url = API+"/addresses"
  
      fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
          }
        })
      .then((response) => {
          return response.json()
      })
      .then((result) => {
        if (result.status.success) {
          setAddresses(result.data)
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error) }}), true);
        }
      }, (error) => {
        console.log(error)
      })
    }
  
    useEffect(() => {
      getAddresses()
      // eslint-disable-next-line
    }, [])
    
    return (
        <div className="addresses">
            <select value={ selectedAddr } onChange={ (e) => { selectAddress(e.target.value) } } required={ required ? true : false }>
                <option value="">Wybierz adres</option>
                { addresses.map((item, i) => {
                    return (
                        <option key={ i } value={ item.uuid }>{ item.name }</option>
                    )
                }) }
            </select>
        </div>
    )
  }