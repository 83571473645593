import React, { useState, useEffect } from 'react'
import { errorMap } from './BaseComponent'
import _ from 'lodash'

export default function Categories({ API, selectCategory, selectedCat, required }) {
    const [categories, setCategories] = useState([])
  
    const getCategories = () => {
      let url = API+"/story/group/list"
      const params = {
        "limit": 100,
        "offset": 0,
        archived: false
      }
  
      fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
          },
          body: JSON.stringify(params)
        })
      .then((response) => {
          return response.json()
      })
      .then((result) => {
        if (result.status.success) {
          setCategories(_.orderBy(result.data?.storyGroups, ["priority"], ["asc"]))
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error) }}), true);
        }
      }, (error) => {
        console.log(error)
      })
    }
  
    useEffect(() => {
      getCategories()
      // eslint-disable-next-line
    }, [])
    
    return (
      <select value={ selectedCat } onChange={ (e) => { selectCategory(e.target.value) } } required={ required ? true : false }>
        <option value="">Select group</option>
        { categories.map((item, i) => {
          return (
            <option key={ i } value={ item.ident }>{ item.content?.title }</option>
          )
        }) }
      </select>
    )
  }