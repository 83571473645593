import React, { useState, useEffect } from 'react'
import { errorMap } from './BaseComponent'

export default function Items({ API, selectItem, selected, required }) {
    const [items, setItems] = useState([])
  
    const getItems = () => {
      let url = API+"/offers?public=1"
  
      fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
          }
        })
      .then((response) => {
          return response.json()
      })
      .then((result) => {
        if (result.status.success) {
          setItems(result.data)
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error) }}), true);
        }
      }, (error) => {
        console.log(error)
      })
    }
  
    useEffect(() => {
      getItems()
      // eslint-disable-next-line
    }, [])
    
    return (
      <select value={ selected.uuid } onChange={ (e) => { selectItem(e.target.value) } } required={ required ? true : false }>
        <option value="">Wybierz Ofertę</option>
        { items.map((item, i) => {
          return (
            <option key={ i } value={ item.uuid }>{ item.title }</option>
          )
        }) }
      </select>
    )
  }